export const EnableLogs = false
export const UserId = '100'

const location = window.location.origin

export const serverUrl = `${location}/cw-retail/graphql`
//prod 
//export const genericUrl = 'https://retaildashboard.decathlonin.net/cw-services/graphql'
//export const tokenUrl = 'https://retaildashboard.decathlonin.net/cw-identity/oauth/token'

//pre-prod
//export const genericUrl = 'https://retaildashboardpp.decathlonin.net/cw-services/graphql'
//export const tokenUrl = 'https://retaildashboardpp.decathlonin.net/cw-identity/oauth/token'

//dec-test

export const genericUrl = `${location}/cw-core-services/graphql`
export const tokenUrl = `${location}/cw-identity/oauth/token`

export const fileDownloadUrl = `${location}:8443`

